export const environment = {
    production: true,
    firebase: {
        vapidKey: 'BBgy06670qgjmktBevTk21mldz0jVANCupgWpCIOtbDqU7Ds3uvf6e9H0LZxOdNUFPPsQRm5ceAzOgPuDNey10g',
        apiKey: 'AIzaSyARky-VIGnhepRDC66tyXj1bfqQAFG-P3Q',
        authDomain: 'dianovatime.firebaseapp.com',
        databaseURL: 'https://dianovatime.firebaseio.com',
        projectId: 'dianovatime',
        storageBucket: 'dianovatime.appspot.com',
        messagingSenderId: '375052070938',
        appId: '1:375052070938:web:3c895c3cccc4fc467021a3',
        measurementId: 'G-RTJRMKYSJP'
    }
};
