import {HammerGestureConfig} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import {Injectable} from "@angular/core";

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    options = {
        touchAction: 'auto',
        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
        recognizers: [
            [Hammer.Swipe, {
                direction: Hammer.DIRECTION_HORIZONTAL
            }]
        ]
    };

    public buildHammer(element: HTMLElement): any {
        return new (window as any).Hammer(element, {touchAction: 'pan-y'});
    }
}
