import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {firebase} from '@firebase/app';
import 'hammerjs';


let  env = environment;


if (env.production) {
    enableProdMode();
}

firebase.initializeApp(env.firebase);

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
