import {NgModule} from '@angular/core';
import {BrowserModule, HammerGestureConfig} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/firestore';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CustomHammerConfig} from './CustomHammerConfig';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {CalendarEventPopoverComponent} from './project/common/popover/calendarEventPopover/calendarEventPopover.component';
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {FullCalendarModule} from "@fullcalendar/angular";
import {GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig} from "ng-gapi/lib/src";

let gapiClientConfig: NgGapiClientConfig = {
    client_id: "375052070938-t7q1sn28qqf275ubqlde81tk836iqjpl.apps.googleusercontent.com",
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
    ux_mode: "popup",
    // redirect_uri: "http://localhost:4200/project/inginer",
    scope: [
        "https://www.googleapis.com/auth/spreadsheets"
    ].join(" ")
};

@NgModule({
    declarations: [AppComponent, CalendarEventPopoverComponent],
    entryComponents: [CalendarEventPopoverComponent],
    imports: [BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence({synchronizeTabs: true}),
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        NgbPopoverModule,
        FullCalendarModule,
        BrowserAnimationsModule,
        GoogleApiModule.forRoot({
            provide: NG_GAPI_CONFIG,
            useValue: gapiClientConfig
        }),],

    providers: [
        StatusBar,
        SplashScreen,
        {provide: HammerGestureConfig, useClass: CustomHammerConfig},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        AngularFirestore
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
